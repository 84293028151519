import {
  handleResponse,
  handleResponseConfig,
} from "helpers/handleAPIResponse";
import { clone } from "lodash-es";

const baseUrl = process.env.REACT_APP_API_DOMAIN;

export const fetchConfig = async (qrCode) => {
  return fetch(`${baseUrl}/visit-mobile/get-config?qr=${qrCode}`, {
    method: "GET",
  })
    .then(handleResponseConfig)
    .then((result) => {
      if (result.error) {
        throw new Error(result.message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get config details failed"); // eslint-disable-line
    });
};

export const saveRegister = async (params) => {
  const newParams = clone(params);
  // remove unnecesary params
  delete newParams["acceptTerms"];

  newParams.channel = "MANUAL";

  return fetch(`${baseUrl}/visit-mobile/register`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "csrf-token": localStorage.getItem("csrf-token"),
      "reg-token": localStorage.getItem("reg-token"),
    },
    body: JSON.stringify(newParams),
  })
    .then(handleResponse)
    .then((result) => {
      if (result.error) {
        throw new Error(result.message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err); // eslint-disable-line÷
    });
};

export const fetchInvitation = async (registrationId) => {
  return fetch(
    `${baseUrl}/visit-mobile/get-invitation-data?registrationId=${registrationId}`,
    {
      method: "GET",
    }
  )
    .then(handleResponse)
    .then((result) => {
      if (result.error) {
        throw new Error(result.message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? "Get invitation details failed"); // eslint-disable-line
    });
};
