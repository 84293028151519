export const handleResponse = (response) => {
  if (response.ok) {
    return response.json();
  }
  throw new Error(`${response.status} Error`);
};

export const handleResponseConfig = (response) => {
  if (response.ok) {
    localStorage.setItem("csrf-token", response.headers.get("csrf-token"));
    localStorage.setItem("reg-token", response.headers.get("reg-token"));

    return response.json();
  }
  throw new Error(`${response.status} Error`);
};
