import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
// import logo from '../images/logo.png';
import QRCode from "qrcode.react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash-es";
import redExclamation from "../images/red-exclamation.png";
import { isDatePassed } from 'helpers/dateFn';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const RegistrationCompletedScreen = () => {
  const { width } = useWindowDimensions();
  const { qrCode, validDate, periodType, config } = useSelector(
    (state) => state.config
  );
  useEffect(() => {
    if (isEmpty(qrCode) || isEmpty(config) || isDatePassed(validDate)) {
      window.location.replace("https://prospace.io");
    }
  }, [qrCode, config, validDate]);

  if (isEmpty(qrCode) || isEmpty(config)) return <Loader type="Puff" color="#f25f4c" />;
  return (
    <div className="app">
      <header className="app__header">
        <img
          src={!isEmpty(config) && config.site.organization.logoUrl}
          className="app__logo"
          alt="logo"
        />
        <p className="margin-top-0 margin-bottom-30">
          {!isEmpty(config) &&
            `${config.site.organization.name} | ${config.site.country} | ${config.siteAlias}`}
        </p>
      </header>
      <main className="app__main">
        <h2>
          <FormattedMessage
            id="registration.complete.title"
            defaultMessage="Registration completed"
            description="Title when completing registration process"
          />{" "}
          ✅
        </h2>
        <p>
          <FormattedMessage
            id="registration.complete.instruction"
            defaultMessage="Please keep this Fast Pass code for check-in at reception counter
          later. A copy has been sent to your email as well."
            description="Further instruction after completing registration process"
          />
        </p>
        <div className="qr-code">
          {!isEmpty(qrCode) && (
            <div className="qr-code__box">
              <QRCode
                value={qrCode}
                size={width < 768 ? width * 0.8 : width * 0.4}
                includeMargin={true}
              />
            </div>
          )}
          <div className="text__icon text--center">
            <img src={redExclamation} alt="red-exclamation" />
            <label>
              <span className="text__description">
                {periodType === "SINGLE_DAY" ? (
                  <FormattedMessage
                    id="registration.complete.qr.info.single.day"
                    defaultMessage="Code valid for today only"
                    description="Info on how long the qr code valid for at single day visit"
                  />
                ) : (
                  <FormattedMessage
                    id="registration.complete.qr.info.multi.day"
                    defaultMessage="Code valid until: {date}"
                    description="Info on how long the qr code valid for at multiday visit"
                    values={{ date: <strong>{validDate}</strong> }}
                  />
                )}
              </span>
            </label>
          </div>
        </div>
      </main>
    </div>
  );
};

export default React.memo(RegistrationCompletedScreen);
