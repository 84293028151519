import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { isNil } from "lodash-es";

const FIELD_HAS_ERROR_CLASS = "field__has-error";

const RegistrationForm = (props) => {
  const { values, onSubmit } = props;

  const intl = useIntl();

  const {
    enableSignature,
    registrationId,
    config: { formSettings, formQuestionnaires, questions },
  } = useSelector((state) => state.config);

  const [agree, setAgree] = useState(true);

  const errorRequiredMessage = intl.formatMessage({
    id: "registration.form.formik.error.required",
    defaultMessage: "Required field",
    description: "Error message mandatory field of Formik",
  });

  const errorInvalidEmailMessage = intl.formatMessage({
    id: "registration.form.formik.error.invalid.email",
    defaultMessage: "Invalid email address",
    description: "Error message mandatory field of Formik",
  });

  const fieldChecker = (fieldName) => {
    return formSettings.find((item) => item.formFieldName === fieldName);
  };

  const formik = useFormik({
    initialValues: {
      ...values,
      visitorForms: formQuestionnaires.map((item) => ({
        question: item.questionText,
        required: item.required,
        formUuid: item.formUuid
      })),
    },
    validationSchema: Yup.object({
      name: Yup.string().required(errorRequiredMessage),
      organization: Yup.string().when([], {
        is: () => fieldChecker("organization").required,
        then: Yup.string().required(errorRequiredMessage),
      }),
      email: Yup.string()
        .email(errorInvalidEmailMessage)
        .when("visitorType", {
          is: (type) => type !== "DELIVERY" && fieldChecker("email").required,
          then: Yup.string()
            .email(errorInvalidEmailMessage)
            .required(errorRequiredMessage),
        }),
      phone: Yup.string().when("visitorType", {
        is: (type) => type === "DELIVERY" || fieldChecker("phone").required,
        then: Yup.string().required(errorRequiredMessage),
      }),
      visitorType: Yup.string().required(errorRequiredMessage),
      visitorForms: Yup.array().of(
        Yup.object().shape({
          formUuid: Yup.string(),
          question: Yup.string(),
          required: Yup.boolean(),
          answer: Yup.string().when("required", {
            is: (value) => value,
            then: Yup.string().required(errorRequiredMessage),
          }),
        })
      ),
      acceptTerms: Yup.array().of(Yup.string()).required(errorRequiredMessage),
    }),
    onSubmit: (values) => {
      console.log(values);
      if (agree) {
        onSubmit(values);
      }
    },
  });

  useEffect(() => {
    setAgree(formik.values.acceptTerms.length === questions.length);
  }, [formik.values.acceptTerms, questions]);

  return (
    <main className="app__main">
      <form className="form" onSubmit={formik.handleSubmit} noValidate>
        <p className="form__description">
          <span className="text__required-mark">*</span>{" "}
          <FormattedMessage
            id="registration.form.required.legend"
            defaultMessage="Required"
            description="Description of asterisk (*) sign in the form"
          />
        </p>
        <div className="form__field">
          <label className="field__label">
            <FormattedMessage
              id="registration.form.label.profile"
              defaultMessage="Profile"
              description="Label for profile select"
            />
          </label>
          <select
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.visitorType}
            name="visitorType"
            className="field__control field__control--select"
            disabled={!isNil(registrationId)}
          >
            <option value="VISITOR">
              {intl.formatMessage({
                id: "registration.form.option.visitor",
                defaultMessage: "Visitor",
                description: "Option visitor in select profile",
              })}
            </option>
            <option value="CONTRACTOR">
              {intl.formatMessage({
                id: "registration.form.option.contractor",
                defaultMessage: "Contractor",
                description: "Option contractor in select profile",
              })}
            </option>
            <option value="DELIVERY">
              {intl.formatMessage({
                id: "registration.form.option.delivery",
                defaultMessage: "Delivery",
                description: "Option delivery in select profile",
              })}
            </option>
          </select>
        </div>
        <div
          className={`form__field ${
            formik.touched.name && formik.errors.name && FIELD_HAS_ERROR_CLASS
          }`}
        >
          <label className="field__label">
            <FormattedMessage
              id="registration.form.label.name"
              defaultMessage="Name"
              description="Label of form field Name"
            />{" "}
            <span className="text__required-mark">*</span>
          </label>
          <input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            name="name"
            className="field__control"
            type="text"
            placeholder={intl.formatMessage({
              id: "registration.form.placeholder.name",
              defaultMessage: "Enter your name",
              description: "Placeholder message of the input Name",
            })}
            readOnly={!isNil(registrationId)}
            required
          />
          {formik.touched.name && formik.errors.name && (
            <p className="field__text-error">{formik.errors.name}</p>
          )}
        </div>
        {fieldChecker("organization").toggleShow && (
          <div
            className={`form__field ${
              formik.touched.organization &&
              formik.errors.organization &&
              FIELD_HAS_ERROR_CLASS
            }`}
          >
            <label className="field__label">
              <FormattedMessage
                id="registration.form.label.organisation"
                defaultMessage="Organisation"
                description="Label of form field Organisation"
              />{" "}
              {fieldChecker("organization").required ? (
                <span className="text__required-mark">*</span>
              ) : (
                <span className="text__description">
                  <FormattedMessage
                    id="registration.form.label.optional"
                    defaultMessage="(Optional)"
                    description="Additional info on label indicating certain field as non mandatory / optional"
                  />
                </span>
              )}
            </label>
            <input
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.organization}
              name="organization"
              className="field__control"
              type="text"
              placeholder={intl.formatMessage({
                id: "registration.form.placeholder.organisation",
                defaultMessage: "Enter your organisation name",
                description: "Placeholder message of the input Organisation",
              })}
              required={fieldChecker("organization").required}
              readOnly={!isNil(registrationId)}
            />
            {formik.touched.organization && formik.errors.organization && (
              <p className="field__text-error">{formik.errors.organization}</p>
            )}
          </div>
        )}
        {fieldChecker("phone").toggleShow && (
          <div
            className={`form__field  ${
              formik.touched.phone &&
              formik.errors.phone &&
              FIELD_HAS_ERROR_CLASS
            }`}
          >
            <label className="field__label">
              <FormattedMessage
                id="registration.form.label.phone"
                defaultMessage="Phone number"
                description="Label of form field phone number"
              />{" "}
              {formik.values.visitorType === "DELIVERY" ||
              fieldChecker("phone").required ? (
                <span className="text__required-mark">*</span>
              ) : (
                <span className="text__description">
                  <FormattedMessage
                    id="registration.form.label.optional"
                    defaultMessage="(Optional)"
                    description="Additional info on label indicating certain field as non mandatory / optional"
                  />
                </span>
              )}
            </label>
            <input
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              name="phone"
              className="field__control"
              type="tel"
              placeholder={intl.formatMessage({
                id: "registration.form.placeholder.phone",
                defaultMessage: "Enter your phone number",
                description: "Placeholder message of input field phone number",
              })}
              required={fieldChecker("phone").required}
              readOnly={!isNil(registrationId)}
            />
            {formik.touched.phone && formik.errors.phone && (
              <p className="field__text-error">{formik.errors.phone}</p>
            )}
          </div>
        )}
        {fieldChecker("email").toggleShow &&
          formik.values.visitorType !== "DELIVERY" && (
            <div
              className={`form__field margin-bottom-24 ${
                formik.touched.email &&
                formik.errors.email &&
                FIELD_HAS_ERROR_CLASS
              }`}
            >
              <label className="field__label">
                <FormattedMessage
                  id="registration.form.label.email"
                  defaultMessage="Email"
                  description="Label of form field Email"
                />{" "}
                {fieldChecker("email").required ? (
                  <span className="text__required-mark">*</span>
                ) : (
                  <span className="text__description">
                    <FormattedMessage
                      id="registration.form.label.optional"
                      defaultMessage="(Optional)"
                      description="Additional info on label indicating certain field as non mandatory / optional"
                    />
                  </span>
                )}
              </label>
              <input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                name="email"
                className="field__control"
                type="email"
                placeholder={intl.formatMessage({
                  id: "registration.form.placeholder.email",
                  defaultMessage: "Enter your email address",
                  description: "Placeholder message of input field Email",
                })}
                required={fieldChecker("email").required}
                readOnly={!isNil(registrationId)}
              />
              {formik.touched.email && formik.errors.email && (
                <p className="field__text-error">{formik.errors.email}</p>
              )}
            </div>
          )}
        {formQuestionnaires.map((additionalQuestion, index) => {
          switch (additionalQuestion.type) {
            case "TEXT":
              return (
                <div
                  className={`form__field margin-bottom-24 ${
                    formik.touched.visitorForms &&
                    formik.errors.visitorForms?.[index] &&
                    FIELD_HAS_ERROR_CLASS
                  }`}
                  key={additionalQuestion.formUuid}
                >
                  <label className="field__label">
                    {additionalQuestion.questionText}{" "}
                    {additionalQuestion?.required ? (
                      <span className="text__required-mark">*</span>
                    ) : (
                      <span className="text__description">
                        <FormattedMessage
                          id="registration.form.label.optional"
                          defaultMessage="(Optional)"
                          description="Additional info on label indicating certain field as non mandatory / optional"
                        />
                      </span>
                    )}
                  </label>
                  <input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name={`visitorForms[${index}].answer`}
                    className="field__control"
                    placeholder={additionalQuestion?.hints}
                    type="text"
                    required={additionalQuestion?.required}
                  />
                  {formik.errors.visitorForms?.[index] && (
                    <p className="field__text-error">
                      {formik.errors.visitorForms?.[index].answer}
                    </p>
                  )}
                </div>
              );

            case "OPTION":
              return (
                <div
                  className="form__field margin-bottom-24"
                  key={additionalQuestion.formUuid}
                >
                  <label className="field__label">
                    {additionalQuestion.questionText}{" "}
                    {additionalQuestion.required ? (
                      <span className="text__required-mark">*</span>
                    ) : (
                      <span className="text__description">
                        <FormattedMessage
                          id="registration.form.label.optional"
                          defaultMessage="(Optional)"
                          description="Additional info on label indicating certain field as non mandatory / optional"
                        />
                      </span>
                    )}
                  </label>
                  {additionalQuestion.optionsText.map((option) => (
                    <div className="field__radio" key={option}>
                      <input
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name={`visitorForms[${index}].answer`}
                        id={option}
                        className="field__radio-button"
                        type="radio"
                        required={additionalQuestion.required}
                        value={option}
                      />
                      <label className="field__radio-label" htmlFor={option}>
                        {option}
                      </label>
                    </div>
                  ))}
                  {formik.errors.visitorForms?.[index] && (
                    <p className="field__text-error">
                      {formik.errors.visitorForms?.[index].answer}
                    </p>
                  )}
                </div>
              );
            default:
              break;
          }
          return false;
        })}
        {questions.map((question, index) => {
          switch (question.questionType) {
            case "CHECKBOX":
              return (
                <div
                  className="form__field margin-bottom-24"
                  key={String(index)}
                >
                  <input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.acceptTerms.includes(
                      `question-${index + 1}`
                    )}
                    name="acceptTerms"
                    className="field__check-control"
                    type="checkbox"
                    id={`checkbox-${index}`}
                    value={`question-${index + 1}`}
                  />
                  <label
                    className="field__check-label"
                    htmlFor={`checkbox-${index}`}
                  >
                    {question.questionText}
                  </label>
                </div>
              );

            default:
              break;
          }

          return false;
        })}
        <button
          type="submit"
          className="button button--primary"
          disabled={!agree}
        >
          {enableSignature
            ? intl.formatMessage({
                id: "registration.form.button.next",
                defaultMessage: "Next",
                description: "Action button to proceed to the next screen",
              })
            : intl.formatMessage({
                id: "registration.form.button.submit",
                defaultMessage: "Submit",
                description: "Action button to submit the form",
              })}
        </button>
      </form>
    </main>
  );
};

export default React.memo(RegistrationForm);
