export function isDatePassed(dateString) {
    const dateParts = dateString.split(' ');

    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const day = parseInt(dateParts[0], 10); 
    const month = monthNames.indexOf(dateParts[1]);
    const year = parseInt(dateParts[2], 10); 

    const targetDate = new Date(year, month, day);

    const today = new Date();

    targetDate.setHours(23, 59, 59, 999);

    return targetDate < today;
}

