import React, {useEffect} from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "normalize.css";
import "./App.scss";
import RegistrationScreen from "./screens/RegistrationScreen";
import RegistrationCompletedScreen from "./screens/RegistrationCompletedScreen";
import RegistrationErrorScreen from "./screens/RegistrationErrorScreen";
import NonDisclosureAgreementScreen from "screens/NonDisclosureAgreementScreen";

function App() {
  useEffect(() => {
  const handleBeforeInstallPrompt = (event) => {
    event.preventDefault();
  };

  window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

  return () => {
    window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  };
}, []);

  return (
    <Router>
      <Switch>
        <Route path="/registration-completed">
          <RegistrationCompletedScreen />
        </Route>
        <Route path="/registration-error">
          <RegistrationErrorScreen />
        </Route>
        <Route path={["//nda", "/nda"]}>
          <NonDisclosureAgreementScreen />
        </Route>
        <Route path="/">
          <RegistrationScreen />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
