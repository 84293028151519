import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { createLogger } from "redux-logger";

import configReducer from "store/configSlice";

const middleware = [
  /* YOUR CUSTOM MIDDLEWARES HERE */
  ...getDefaultMiddleware(),
];
if (process.env.NODE_ENV !== "production") {
  const logger = createLogger();
  // add logger middleware
  middleware.push(logger);
}

const combinedReducer = combineReducers({
  config: configReducer,
});

const rootReducer = (state, action) => {
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware,
});

export default store;
