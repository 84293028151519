import React from "react";
import { FormattedMessage } from "react-intl";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash-es";

export default function RegistrationErrorScreen() {
  const queryCode = localStorage.getItem("queryString");

  return (
    <div className="app">
      <header className="app__header">
        <img src={logo} className="app__logo" alt="logo" />
      </header>
      <main className="app__main">
        {/* <h2>Oops! Something error here ❌</h2> */}

        {isEmpty(queryCode) ? (
          <h2>
            <FormattedMessage
              id="registration.error.message"
              defaultMessage="Please <link>try again</link>."
              description="Registration error title"
              values={{
                link: (text) => <Link to={`/?qr=${queryCode}`}>{text}</Link>,
              }}
            />
          </h2>
        ) : (
          <h2>
            <FormattedMessage
              id="registration.error.info.invalid.token"
              defaultMessage="Invalid Token"
              description="Registration error message on invalid token"
            />
          </h2>
        )}
      </main>
    </div>
  );
}
